import { combineReducers, configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { clearUserData } from '../utils/utils';
import authSliceReducer, { authApi } from './AuthSlice';
import benefitsPageSliceReducer, { benefitsDataApi } from './BenefitsPageSlice.js';
import bookSliceReducer, { bookingApi } from './BookingSlice';
import { comparedFlatsApi } from './ComparedFlatsSlice';
import componentsSliceReducer, { handleIsError } from './ComponentsSlice.js';
import equalizerReducer from './EqualizerSlice.js';
import favoritesReducer, { favoritesApi } from './FavoritesSlice';
import meetingsSliceReducer, { meetingsApi } from './MeetingsSlice';
import modalFilterReducer from './ModalFilterSlice';
import monitorSliceReducer from './MonitorSlice.js';
import { presentationApi } from './PresentationsSlice';
import projectsSliceReducer from './ProjectSlice.js';
import wabaReducer, { contactsApi } from './WabaSlice';
import catalogSlice from './catalogSlice';
import formSlice, { formApi } from './formSlice.js';
import formSliceStep, { formCommercialManagerApi } from './formSliceStep.js';
import catalogWorkplaceSlice from './catalogWorkplaceSlice.js';
import accreditationSliceReducer, { accreditationApi } from './AccreditationSlice.js';
import connectionSliceReducer from './connectionSlice.js';
import meetingsScheduleReducer, { meetingsScheduleApi } from './MeetingsScheduleSlice.js';
import partnerProgramsReducer, { partnerProgramsApi } from './PartnerProgramsSlice.js';
import sideMenuReducer from './SideMenuSlice.js';

// Middleware для перехвата ошибок
const errorHandlingMiddleware =
  ({ dispatch }) =>
  next =>
  action => {
    if (action.type.includes('Api')) {
      const type = action?.type;
      const meta = action?.meta;

      const timeStamp = meta?.startedTimeStamp;
      const isSuccess = type.endsWith('/fulfilled');

      const endTime = meta?.fulfilledTimeStamp;
      if (timeStamp) sessionStorage.setItem('startTime', String(timeStamp));

      const request = meta?.baseQueryMeta?.request;

      if (isSuccess && request) {
        const requestTimeInMs = endTime - Number(sessionStorage.getItem('startTime'));

        console.log(`${request.method} ${request.url} +${requestTimeInMs}ms`);
        return next(action);
      }
    }

    // Проверяем, является ли действие отклоненным значением из RTK Query
    if (isRejectedWithValue(action)) {
      const { status } = action.payload;
      console.log('STORE_ERROR', action);

      if (
        action.type === 'authApi/executeMutation/rejected' ||
        action.type === 'contactsApi/executeMutation/rejected'
      ) {
        return next(action);
      }

      if (status === 401) {
        // Обрабатываем 401 ошибку, если она не произошла при авторизации
        clearUserData();
      }

      // при возникновении конфликта данных прерываем цепочку и обрабатываем ответ с бэка по месту
      if (status === 409) {
        return;
      }

      dispatch(handleIsError({ isError: true, code: status, message: action.payload?.data?.error }));
    }

    // Передаем действие дальше по цепочке middleware
    return next(action);
  };

const reducers = combineReducers({
  [contactsApi.reducerPath]: contactsApi.reducer,
  [comparedFlatsApi.reducerPath]: comparedFlatsApi.reducer,
  [bookingApi.reducerPath]: bookingApi.reducer,
  [favoritesApi.reducerPath]: favoritesApi.reducer,
  [presentationApi.reducerPath]: presentationApi.reducer,
  [meetingsApi.reducerPath]: meetingsApi.reducer,
  [benefitsDataApi.reducerPath]: benefitsDataApi.reducer,
  [formApi.reducerPath]: formApi.reducer,
  [formCommercialManagerApi.reducerPath]: formCommercialManagerApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [meetingsScheduleApi.reducerPath]: meetingsScheduleApi.reducer,
  [partnerProgramsApi.reducerPath]: partnerProgramsApi.reducer,
  [accreditationApi.reducerPath]: accreditationApi.reducer,
  meetingsData: meetingsSliceReducer,
  favoritesData: favoritesReducer,
  componentsState: componentsSliceReducer,
  userInfo: authSliceReducer,
  projectsInfo: projectsSliceReducer,
  monitorInfo: monitorSliceReducer,
  modalFilter: modalFilterReducer,
  equalizerInfo: equalizerReducer,
  catalogPage: catalogSlice,
  catalogWorkplacePage: catalogWorkplaceSlice,
  waba: wabaReducer,
  formSlice,
  formSliceStep,
  bookData: bookSliceReducer,
  benefitsData: benefitsPageSliceReducer,
  accreditationSlice: accreditationSliceReducer,
  connectionSlice: connectionSliceReducer,
  meetingsScheduleSlice: meetingsScheduleReducer,
  partnerProgramsSlice: partnerProgramsReducer,
  sideMenu: sideMenuReducer,
});

export const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      contactsApi.middleware,
      presentationApi.middleware,
      meetingsApi.middleware,
      favoritesApi.middleware,
      bookingApi.middleware,
      comparedFlatsApi.middleware,
      benefitsDataApi.middleware,
      formApi.middleware,
      formCommercialManagerApi.middleware,
      meetingsScheduleApi.middleware,
      partnerProgramsApi.middleware,
      accreditationApi.middleware,
      errorHandlingMiddleware,
    ),
});

setupListeners(store.dispatch);
