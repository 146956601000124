import { useEffect } from 'react';
import { initialProjects } from '../../../../widgets/calculator/calculator-logic/initialProjects';
import { isFlatSelected } from '../../../../utils/utils';

export const useAutoSlideLogic = ({ swiperRef, favoriteFlats, propsForFlatCards, currFlatIdFavourite }) => {
  useEffect(() => {
    if (swiperRef.current && favoriteFlats?.length) {
      const activeIndex = favoriteFlats.findIndex(flat => {
        const project = initialProjects.find(i => flat.block_id === i.blockId) || [];
        return isFlatSelected({
          flatData: propsForFlatCards?.flatData,
          flat,
          currFlatIdFavourite,
          project,
          activeProjectId: propsForFlatCards?.activeProjectId,
        });
      });

      if (activeIndex !== -1) {
        swiperRef.current.slideTo(activeIndex, 500, false);
      }
    }
  }, [favoriteFlats, propsForFlatCards, currFlatIdFavourite, swiperRef]);
};
