import { Box, Card, Stack, ThemeProvider, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Colors } from '../../../assets/mui-styles/Colors';
import { equalizerTheme } from '../../../assets/mui-styles/GlobalStyles';
import { EqualizerCompareButton } from '../../equalizer-page-new/equalizer-compare-button/EqualizerCompareButton';
import DOMPurify from 'dompurify';
import MortgageColumnBox from '../../../widgets/mortgage-column-box/MortgageColumnBox.jsx';
import { isDiscountInPriceCalculatorVisible } from '../../../utils/feature-flags.js';

export const EstimationBlock = ({
  flatPrice,
  currentBenefit,
  calcData,
  initialPayment,
  fetchStatus,
  flatsData,
  meetingId,
  termCommitted,
  flatData,
  isLotTab,
  isEqualizer,
  firstPaymentCommitted,
  benefits,
  priceWithMargin,
  isFreeTab = false,
  freeCreditSum,
  freeMonthlyPayment,
}) => {
  const monthlyPaymentSubsidy = calcData?.monthlyPaymentSubsidy;
  const monthlySubsidyExists = Boolean(monthlyPaymentSubsidy);
  const amountOfCredit =
    isLotTab && isEqualizer
      ? currentBenefit?.benefitPrice - currentBenefit?.mortgage?.firstPayment
      : calcData?.amountOfCredit || initialPayment?.amountOfCredit;
  const normalMonthlyPayment =
    isLotTab && isEqualizer ? currentBenefit?.mortgage?.minMonthFee : calcData?.monthlyPayment;
  const subsidyTerm = calcData?.subsidyTerm;
  const paymentTerm = subsidyTerm + 1 === 2 ? 'cо' : `c ${subsidyTerm + 1} года`;

  const displaySaldo =
    !isLotTab &&
    !isFreeTab &&
    !isEqualizer &&
    typeof calcData?.discountSum === 'number' &&
    typeof calcData?.discountPercent === 'number';

  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const year = today.getFullYear();
  const formattedDate = `${day}.${month}.${year}`;

  return (
    <EstimationBlockContainer>
      {fetchStatus === 'pending' && <StyledWhiteOverlay />}

      <StyledBlockStack spacing='24px'>
        {currentBenefit && !isFreeTab && (
          <Stack
            direction='column'
            alignItems='start'
            justifyContent='start'
            spacing='24px'
            sx={{
              padding: '0 16px',
              width: '100%',
            }}
          >
            <Box sx={{ width: '100%' }}>
              <Typography
                sx={{
                  fontSize: '24px',
                  lineHeight: '44px',
                  fontWeight: '500',
                  letterSpacing: '-0.5px',
                }}
              >
                {currentBenefit?.name}
              </Typography>
            </Box>

            <Stack
              display='grid'
              gridTemplateColumns='repeat(auto-fit, minmax(100px, 1fr))'
              gap='24px'
              sx={{
                width: '100%',
              }}
            >
              <Box sx={{ width: '100%' }}>
                {currentBenefit.description && (
                  <Typography
                    sx={{
                      fontSize: 12,
                      lineHeight: '150%',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(currentBenefit?.description),
                    }}
                  />
                )}

                {currentBenefit.minFirstPaymen && (
                  <StyledDescription>ПВ от {currentBenefit.minFirstPaymen}%</StyledDescription>
                )}

                {Boolean(currentBenefit.rate) && <StyledDescription>Ставка {currentBenefit.rate}%</StyledDescription>}

                {currentBenefit.maxYears && (
                  <StyledDescription>Срок до {currentBenefit.maxYears} лет</StyledDescription>
                )}

                {currentBenefit.maxSumCredit && (
                  <StyledDescription>Сумма кредита до {currentBenefit.maxSumCredit / 1000000} млн.р</StyledDescription>
                )}
              </Box>

              {flatPrice && (
                <StyledPrice>
                  <StyledItemPrice>
                    {new Intl.NumberFormat('ru-RU').format(Number.parseInt(flatPrice, 10))} ₽
                  </StyledItemPrice>
                  <p>Прайсовая цена</p>
                </StyledPrice>
              )}
            </Stack>
          </Stack>
        )}

        {(initialPayment?.priceWithMargin || priceWithMargin) && !isFreeTab && (
          <StyledInnerStack>
            <Stack sx={{ gap: '20px' }}>
              <MortgageColumnBox
                title={'Стоимость квартиры по программе'}
                value={calcData?.priceWithMargin ?? initialPayment?.priceWithMargin ?? priceWithMargin}
              />
              {monthlySubsidyExists && (
                <MortgageColumnBox title={'Ежемесячный платеж на льготный период'} value={monthlyPaymentSubsidy} />
              )}
              {displaySaldo && isDiscountInPriceCalculatorVisible && (
                <>
                  <Box sx={{ width: '317.5px', height: '103px' }} />
                  <MortgageColumnBox
                    toFixedValue={1}
                    isBlack={true}
                    title={'Скидка в ₽'}
                    value={calcData?.discountSum}
                  />
                </>
              )}
            </Stack>

            {(!(isLotTab && isEqualizer) || currentBenefit?.mortgage) && (
              <Stack sx={{ gap: '20px' }}>
                <MortgageColumnBox title='Сумма кредита' value={amountOfCredit} />
                <MortgageColumnBox
                  title={monthlySubsidyExists ? `Ежемесячный платеж ${paymentTerm}` : 'Ежемесячный платеж'}
                  value={normalMonthlyPayment}
                />
                {displaySaldo && isDiscountInPriceCalculatorVisible && (
                  <MortgageColumnBox
                    isBlack={true}
                    title={'Скидка в %'}
                    type='percent'
                    value={calcData?.discountSum === 0 ? 0 : calcData?.discountPercent}
                  />
                )}
                <DateTypography marginTop='29px'>{`Дата расчета ${formattedDate}`}</DateTypography>
              </Stack>
            )}
          </StyledInnerStack>
        )}

        {isLotTab && isEqualizer && (
          <ThemeProvider theme={equalizerTheme}>
            <StyledButtonStack>
              {currentBenefit?.mortgage !== null && currentBenefit?.group === 'mortgage' && (
                <EqualizerCompareButton
                  currentBenefit={currentBenefit}
                  meetingId={meetingId}
                  loanTerm={termCommitted}
                  firstPaymentCommitted={firstPaymentCommitted}
                  flatData={flatData}
                />
              )}
            </StyledButtonStack>
          </ThemeProvider>
        )}
        {isFreeTab && (
          <Box width={'100%'}>
            <StyledTypographyFreeCalculatorNew>Предварительный расчет</StyledTypographyFreeCalculatorNew>
            <StyledBoxFreeNew>
              <MortgageColumnBox title='Сумма кредита' value={freeCreditSum} />
              <MortgageColumnBox title='Ежемесячный платеж' value={freeMonthlyPayment} />
            </StyledBoxFreeNew>
            <DateTypography marginTop='49px'>{`Дата расчета ${formattedDate}`}</DateTypography>
          </Box>
        )}
      </StyledBlockStack>
    </EstimationBlockContainer>
  );
};

const StyledBoxFreeNew = styled(Box)({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '21px',
  marginTop: '20px',
});

const StyledTypographyFreeCalculatorNew = styled(Typography)({
  fontSize: '28px',
  lineHeight: '44px',
  fontWeight: '500',
  letterSpacing: '-0.5px',
});

const DateTypography = styled(Typography)({
  fontSize: '20px',
  fontWeight: '500',
  letterSpacing: '-0.5px',
  color: '#302E2D99',
  textAlign: 'right',
});

const EstimationBlockContainer = styled(Card)({
  position: 'relative',
  width: '100%',
  height: 'fit-content',
  padding: '30px calc(24 / 1440 * 100vw) 20px',
  border: `1px solid ${Colors.superLightGrey}`,
  background: Colors.additional,
  marginTop: '57px',
});

const StyledInnerStack = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '24px',
  alignItems: 'flex-start',
  width: '100%',
});

const StyledItemPrice = styled(Typography)({
  fontSize: '28px !important',
  lineHeight: '32.68px !important',
});

const StyledPrice = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  padding: '0 16px',
  fontSize: '24px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  width: '100%',
  '& p': {
    marginTop: '8px',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
  },
});

const StyledWhiteOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: Colors.primary,
  opacity: 0.6,
});

const StyledDescription = styled('p')({
  fontSize: '12px',
  lineHeight: '150%',
});

const StyledButtonStack = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  gap: '21px',
  justifyContent: 'space-between',
  width: '100%',
  marginTop: '20px',
  '@media screen and (min-width: 2560px)': {
    gap: '30px',
    height: '44px',
  },
});

const StyledBlockStack = styled(Stack)({
  width: '100%',
  height: '100%',
  direction: 'column',
  alignItems: 'start',
  justifyContent: 'start',
});
