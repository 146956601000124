import { createSlice } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DOMAIN_RELATIVE } from '../utils/constants';
import { apiRoutes } from './api/api';
import { meetingsScheduleAdapter } from '../pages/meetings-schedule-page/adapters/meetingsScheduleAdapter';

const initialState = {
  operatingModeModal: false,
  createEventDialog: false,
  selectedRanges: null,
  selectedBar: null,
  activeTimelineProject: null,
  selectedRow: null,
  projectManagers: null,
  projectEvents: null,
  lastCreatedBar: null,
  originalProjectManagers: null,
};

export const meetingsScheduleSlice = createSlice({
  name: 'meetingsSchedule',
  initialState,
  reducers: {
    setOpenOpModeModal(state) {
      state.operatingModeModal = true;
    },
    setCloseOpModeModal(state) {
      state.operatingModeModal = false;
    },
    setOpenCreateEventDialog(state) {
      state.createEventDialog = true;
    },
    setCloseCreateEventDialog(state) {
      state.createEventDialog = false;
    },
    setSelectedRanges(state, action) {
      state.selectedRanges = action.payload;
    },
    setSelectedBar(state, action) {
      state.selectedBar = action.payload;
    },
    setActiveTimelineProject(state, action) {
      state.activeTimelineProject = action.payload;
    },
    setSelectedRow(state, action) {
      state.selectedRow = action.payload;
    },
    setLastCreatedBar(state, action) {
      state.lastCreatedBar = action.payload;
    },
    showLastCreatedBar(state) {
      state.selectedBar = state.lastCreatedBar;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(meetingsScheduleApi.endpoints.getProjectMeetings.matchFulfilled, (state, { payload }) => {
      state.projectManagers = payload.managers;
      state.projectEvents = payload.events;
      state.originalProjectManagers = payload.originalManagers;
    });
  },
});

export const meetingsScheduleApi = createApi({
  reducerPath: 'meetingsScheduleApi',
  baseQuery: fetchBaseQuery({
    baseUrl: DOMAIN_RELATIVE,
    credentials: 'include',
  }),
  tagTypes: [
    'Meet',
    'CancelMeet',
    'Briefing',
    'CancelBriefing',
    'Lunch',
    'CancelLunch',
    'OpMode',
    'Vacation',
    'DaysOff',
    'FreeRooms',
    'FreeManagers',
  ],
  endpoints: builder => ({
    getProjectMeetings: builder.query({
      query: ({ projectId, date }) => `${apiRoutes.meetingsSchedule}?projectId=${projectId}&date=${date}`,
      transformResponse: (response, _, arg) => {
        const managers = meetingsScheduleAdapter.adaptManagerList(response.managers);
        const events = meetingsScheduleAdapter.adaptMeetingsSlots(response.events, arg.date);
        return { managers, events, originalManagers: response.managers };
      },
      providesTags: [
        'Meet',
        'CancelMeet',
        'Briefing',
        'CancelBriefing',
        'Lunch',
        'CancelLunch',
        'OpMode',
        'Vacation',
        'DaysOff',
      ],
    }),
    getManagerSchedule: builder.query({
      query: () => apiRoutes.scheduleMonth,
      transformResponse: response => {
        if (response.schedule.length) {
          return response.schedule.map(item => ({
            ...item,
            persisted: true,
          }));
        }

        return response.schedule;
      },
      providesTags: ['OpMode'],
    }),
    createOperatingMode: builder.mutation({
      query: data => ({
        url: apiRoutes.operatingMode,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['OpMode'],
    }),
    deleteOperatingMode: builder.mutation({
      query: opModeId => ({
        url: `${apiRoutes.operatingMode}/${opModeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['OpMode'],
    }),
    getVacationsManager: builder.query({
      query: () => apiRoutes.upcomingVacations,
      transformResponse: response => {
        if (response.length) {
          return response.map(item => ({
            ...item,
            persisted: true,
          }));
        }

        return response;
      },
      providesTags: ['Vacation'],
    }),
    createVacationManager: builder.mutation({
      query: data => ({
        url: apiRoutes.vacationsManager,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Vacation'],
    }),
    deleteVacationManager: builder.mutation({
      query: vacationId => ({
        url: `${apiRoutes.vacationsManager}/${vacationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Vacation'],
    }),
    createMeeting: builder.mutation({
      query: data => ({
        url: apiRoutes.createMeeting,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Meet', 'FreeRooms', 'FreeManagers'],
    }),
    getFreeRooms: builder.query({
      query: ({ projectId, meetingStart, meetingEnd }) =>
        `${apiRoutes.freeRooms}?projectId=${projectId}&startDate=${meetingStart}&endDate=${meetingEnd}`,
      providesTags: ['FreeRooms'],
    }),
    getMeetingById: builder.query({
      query: meetingId => `${apiRoutes.meetingById}/${meetingId}`,
      providesTags: ['Meet'],
    }),
    cancelMeeting: builder.mutation({
      query: ({ data, meetingId }) => ({
        url: `${apiRoutes.cancelMeeting}/${meetingId}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['CancelMeet', 'FreeRooms', 'FreeManagers'],
    }),
    transferMeeting: builder.mutation({
      query: data => ({
        url: `${apiRoutes.transferMeeting}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Meet', 'FreeRooms', 'FreeManagers'],
    }),
    getFreeManagers: builder.query({
      query: ({ projectId, meetingStart, meetingEnd }) =>
        `${apiRoutes.freeManagers}?projectId=${projectId}&startDate=${meetingStart}&endDate=${meetingEnd}`,
      providesTags: ['FreeManagers'],
    }),
    createBriefing: builder.mutation({
      query: data => ({
        url: apiRoutes.createBriefing,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Briefing', 'FreeManagers', 'FreeRooms'],
    }),
    getBriefingById: builder.query({
      query: briefingId => `${apiRoutes.briefing}/${briefingId}`,
      providesTags: ['Briefing'],
    }),
    cancelBriefing: builder.mutation({
      query: briefingId => ({
        url: `${apiRoutes.briefing}/${briefingId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CancelBriefing', 'FreeRooms', 'FreeManagers'],
    }),
    transferBriefing: builder.mutation({
      query: ({ data, briefingId }) => ({
        url: `${apiRoutes.briefing}/${briefingId}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Briefing', 'FreeRooms', 'FreeManagers'],
    }),
    createLunch: builder.mutation({
      query: data => ({
        url: apiRoutes.lunch,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Lunch'],
    }),
    cancelLunch: builder.mutation({
      query: lunchId => ({
        url: `${apiRoutes.lunch}/${lunchId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CancelLunch'],
    }),
    getLunchById: builder.query({
      query: lunchId => `${apiRoutes.lunch}/getById/${lunchId}`,
      providesTags: ['Lunch'],
    }),
    getDaysOff: builder.query({
      query: date => `${apiRoutes.daysOff}?date=${date}`,
      providesTags: ['DaysOff'],
    }),
    updateDaysOff: builder.mutation({
      query: data => ({
        url: apiRoutes.updateDaysOff,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['DaysOff'],
    }),
    getLeadInterest: builder.query({
      query: ({ phone, projectId }) => `${apiRoutes.leadInterest}/${phone}?projectId=${projectId}`,
    }),
    getEventsByManagerId: builder.query({
      query: ({ projectId, date, managerId }) =>
        `${apiRoutes.meetingsSchedule}?projectId=${projectId}&date=${date}&managerId=${managerId}`,
      transformResponse: response => {
        return response.events;
      },
      providesTags: ['Meet', 'CancelMeet', 'Briefing', 'CancelBriefing', 'Lunch', 'CancelLunch'],
    }),
    getProjectsByManagerId: builder.query({
      query: ({ managerId, date }) => `${apiRoutes.projectsByManager}/${managerId}?date=${date}`,
    }),
    getManagersSchedule: builder.query({
      query: ({ projectId, date }) => `${apiRoutes.managersSchedule}?projectId=${projectId}&date=${date}`,
    }),
  }),
});

export const selectOpModeModalStatus = state => state.meetingsScheduleSlice.operatingModeModal;
export const selectCreateEventDialogStatus = state => state.meetingsScheduleSlice.createEventDialog;
export const selectSelectedRanges = state => state.meetingsScheduleSlice.selectedRanges;
export const selectSelectedBar = state => state.meetingsScheduleSlice.selectedBar;
export const selectActiveTimelineProject = state => state.meetingsScheduleSlice.activeTimelineProject;
export const selectSelectedRow = state => state.meetingsScheduleSlice.selectedRow;
export const selectProjectManagers = state => state.meetingsScheduleSlice.projectManagers;
export const selectProjectEvents = state => state.meetingsScheduleSlice.projectEvents;
export const selectOriginalProjectManagers = state => state.meetingsScheduleSlice.originalProjectManagers;

export const {
  setOpenOpModeModal,
  setCloseOpModeModal,
  setOpenCreateEventDialog,
  setCloseCreateEventDialog,
  setSelectedRanges,
  setSelectedBar,
  setActiveTimelineProject,
  setSelectedRow,
  setLastCreatedBar,
  showLastCreatedBar,
} = meetingsScheduleSlice.actions;

export const {
  useGetProjectMeetingsQuery,
  useGetManagerScheduleQuery,
  useCreateOperatingModeMutation,
  useDeleteOperatingModeMutation,
  useGetVacationsManagerQuery,
  useCreateVacationManagerMutation,
  useDeleteVacationManagerMutation,
  useCreateMeetingMutation,
  useGetFreeRoomsQuery,
  useGetMeetingByIdQuery,
  useGetFreeManagersQuery,
  useCreateBriefingMutation,
  useCreateLunchMutation,
  useGetDaysOffQuery,
  useUpdateDaysOffMutation,
  useGetLeadInterestQuery,
  useGetEventsByManagerIdQuery,
  useGetProjectsByManagerIdQuery,
  useGetManagersScheduleQuery,
} = meetingsScheduleApi;

export default meetingsScheduleSlice.reducer;
