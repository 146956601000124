import { Box, InputAdornment, styled } from '@mui/material';
import { CustomInputWithSlider } from './CustomInputWithSlider';
import ErrorIcon from '@mui/icons-material/Error';
import { CustomSwitch } from './CustomSwitch';
import { MIN_GRACE_PERCENT, MAX_GRACE_PERCENT, MIN_GRACE_TERM, MAX_GRACE_TERM } from '../consts/consts';

export const GracePeriodSection = ({
  isGracePeriod,
  toggleGracePeriod,
  gracePercentForDisplay,
  onChangeGracePercent,
  graceTermForDisplay,
  onChangeGraceTerm,
  termForDisplayNew,
  fetchStatus,
}) => {
  const isFetching = fetchStatus === 'pending';
  const isGracePercentError = gracePercentForDisplay < MIN_GRACE_PERCENT || gracePercentForDisplay > MAX_GRACE_PERCENT;
  const isGraceTermError =
    graceTermForDisplay < MIN_GRACE_TERM ||
    graceTermForDisplay > MAX_GRACE_TERM ||
    graceTermForDisplay >= termForDisplayNew;

  const handleInputChange = (value, callback) => {
    // не даем ввести больше 2 символов
    if (String(value).length <= 2) {
      callback(value);
    }
  };

  const getPercentHelperText = () => {
    if (isGracePercentError) return `Процент льготного периода от ${MIN_GRACE_PERCENT} до ${MAX_GRACE_PERCENT}`;
    return '';
  };

  const getTermHelperText = () => {
    if (graceTermForDisplay < MIN_GRACE_TERM || graceTermForDisplay > MAX_GRACE_TERM) {
      return `Льготный период от ${MIN_GRACE_TERM} до ${MAX_GRACE_TERM} лет`;
    }
    if (graceTermForDisplay >= termForDisplayNew) {
      return 'Льготный период должен быть меньше срока кредита';
    }
    return '';
  };

  return (
    <StyledGracePeriodBox>
      <StyledGraceSwitcherContainer>
        <CustomSwitch
          checked={isGracePeriod}
          onClick={toggleGracePeriod}
          label='Льготный период'
          sx={{
            position: 'relative',
            right: '10px',
          }}
        />
        <StyledErrorIcon aria-label='Информация о льготном периоде' />
        <StyledGraceHoverCaption>Ставка на короткий период</StyledGraceHoverCaption>
      </StyledGraceSwitcherContainer>
      {isGracePeriod && (
        <Box display='flex' gap='20px'>
          <Box width='40%'>
            <CustomInputWithSlider
              disabled={isFetching}
              helperText={getPercentHelperText()}
              error={isGracePercentError}
              onChange={e => onChangeGracePercent(e)}
              onChangeInput={e => {
                if ((e >= MIN_GRACE_PERCENT && e <= MAX_GRACE_PERCENT) || !e || e === 0) {
                  handleInputChange(e, onChangeGracePercent);
                }
              }}
              value={gracePercentForDisplay}
              label='Процент льготного периода'
              min={MIN_GRACE_PERCENT}
              max={MAX_GRACE_PERCENT}
              minValueText={MIN_GRACE_PERCENT}
              maxValueText={MAX_GRACE_PERCENT}
              inputType='number'
              endAdornment={<InputAdornment position='end'>%</InputAdornment>}
              fetchStatus={fetchStatus}
            />
          </Box>
          <Box width='24%'>
            <CustomInputWithSlider
              disabled={isFetching}
              helperText={getTermHelperText()}
              error={isGraceTermError}
              onChange={e => onChangeGraceTerm(e)}
              onChangeInput={e => {
                if ((e >= MIN_GRACE_TERM && e <= MAX_GRACE_TERM) || !e || e === 0) {
                  handleInputChange(e, onChangeGraceTerm);
                }
              }}
              value={graceTermForDisplay}
              label='Срок льготного периода'
              min={MIN_GRACE_TERM}
              max={MAX_GRACE_TERM}
              minValueText={MIN_GRACE_TERM}
              maxValueText={MAX_GRACE_TERM}
              inputType='number'
              endAdornment={<InputAdornment position='end'>лет</InputAdornment>}
              fetchStatus={fetchStatus}
            />
          </Box>
        </Box>
      )}
    </StyledGracePeriodBox>
  );
};

const StyledGracePeriodBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
});

const StyledErrorIcon = styled(ErrorIcon)({
  position: 'relative',
  cursor: 'pointer',
  ':hover + div': {
    opacity: 1,
    visibility: 'visible',
  },
});

const StyledGraceSwitcherContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginTop: '3px',
  position: 'relative',
  width: 'fit-content',
});

const StyledGraceHoverCaption = styled(Box)({
  position: 'absolute',
  backgroundColor: '#616161E5',
  color: 'white',
  top: '-17px',
  right: '-60px',
  fontSize: '10px',
  fontWeight: 500,
  lineHeight: '14px',
  padding: '4px 8px',
  whiteSpace: 'nowrap',
  opacity: 0,
  visibility: 'hidden',
  transition: 'opacity 0.3s, visibility 0.3s',
  '::after': {
    content: '""',
    position: 'absolute',

    width: '6px',
    height: '6px',
    bottom: '-9.5px',
    left: '50%',
    transform: 'translateX(-50%)',
    borderWidth: '5px',
    borderStyle: 'solid',
    borderColor: '#616161E5 transparent transparent transparent',
  },
});
