import { Stack, Typography, styled } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bookModalDateSelector, changeBookFlat, changeBookModalDate } from '../../entities/BookingSlice';
import { ruRU } from '@mui/x-date-pickers/locales';

function BookCalendar() {
  const dispatch = useDispatch();

  const flatStorage = sessionStorage.getItem('currFlatEq');
  const flatFavouriteStorage = sessionStorage.getItem('flatFavouriteEq');

  const bookDate = useSelector(bookModalDateSelector());

  const handleDataChange = data => {
    if (data && dayjs(data).isValid()) {
      const formattedDate = data.toISOString();
      dispatch(changeBookModalDate(formattedDate));
    }
  };

  const addDays = days => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date.toISOString();
  };

  useEffect(() => {
    dispatch(changeBookModalDate(addDays(10)));

    if (flatStorage) {
      dispatch(changeBookFlat(flatStorage));
    } else if (flatFavouriteStorage) {
      dispatch(changeBookFlat(flatFavouriteStorage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack direction='column' spacing='5px' width='100%'>
      <Typography variant='h3' mb='20px'>
        Бронирование до
      </Typography>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={ru}
        localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
      >
        <StyledDatePicker
          value={bookDate ? dayjs(bookDate) : null}
          format='DD/MM/YYYY'
          onChange={data => handleDataChange(data)}
        />
      </LocalizationProvider>
    </Stack>
  );
}

export default BookCalendar;

const StyledDatePicker = styled(DatePicker)({
  '& input': {
    minHeight: '40px',
    padding: '0px 12px',
    fontSize: '16px',
  },

  '& button': {
    margin: 0,
  },
});
