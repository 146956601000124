export const accreditationList = import.meta.env.VITE_ACCREDITATION_19993 === 'true';
export const workingSchedule = import.meta.env.VITE_WORK_SCHEDULE === 'true';
export const partnerPrograms = import.meta.env.VITE_PARTNER_PROGRAMS_21505 === 'true';
export const calculatorFreeTab = import.meta.env.VITE_CALCULATOR_FREE_TAB_21868 === 'true';
export const bookingRedirectToPartner = import.meta.env.VITE_BOOKING_REDIRET_TO_PARTNER_23195 === 'true';
export const newCdnForRetailProjects = import.meta.env.VITE_NEW_CDN_FOR_RETAIL_PROJECTS_23390 === 'true';
export const pdfButtonInCalculatorForFree = import.meta.env.VITE_PDF_DOWNLOAD_AND_SEND_TO_MAIL_23657 === 'true';
export const isPaveVisibleInCalculator = import.meta.env.VITE_PAVE_VISIBLE_IN_CALCULATOR === 'true';
export const isGracePeriodInCalculatorForFree =
  import.meta.env.VITE_GRACE_PERIOD_IN_CALCULATOR_FOR_FREE_23881 === 'true';
export const isResetPasswordVisible = import.meta.env.VITE_RESET_PASSWORD_VISIBLE_25144 === 'true';
export const RopScheduleActive = import.meta.env.VITE_ROP_SCHEDULE_ACTIVE_25958 === 'true';
export const isDiscountInPriceCalculatorVisible =
  import.meta.env.VITE_DISCOUNT_BLOCK_IN_CALCULATOR_VISIBLE_25929 === 'true';
export const enablePaveletskayaV3Export = import.meta.env.VITE_PAVELETSKAYA_V3_PDF_EXPORT_26420 === 'true';

// СТарые флаги
export const catalogRetail = import.meta.env.VITE_CATALOG_RETAIL_21560 === 'true';
