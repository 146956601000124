import { sendPdfToEmail } from './dataFetchers';
import { formatEarlyPayments } from '../utils/utils';

export const useSendEmailForPdf = ({ handleSetLoadedStatus, handleSetPendingStatus }) => {
  const onSendEmail = async ({
    emailData,
    propertyPrice,
    initialPayment,
    loanTerm,
    interestRate,
    earlyPayments,
    earlyPaymentsType,
    gracePeriodRate,
    gracePeriodYears,
    isGracePeriod,
  }) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+$/;
    const formattedEarlyPayments = formatEarlyPayments({ earlyPayments, earlyPaymentsType });
    const formattedEmailData = emailData
      .map(emailObject => emailObject?.email)
      ?.filter(email => emailRegex.test(email));

    handleSetPendingStatus();

    await sendPdfToEmail({
      email: formattedEmailData,
      propertyPrice,
      initialPayment,
      loanTerm,
      interestRate,
      earlyPayments: formattedEarlyPayments,
      gracePeriodRate: isGracePeriod ? gracePeriodRate : 0,
      gracePeriodYears: isGracePeriod ? gracePeriodYears : 0,
    });

    handleSetLoadedStatus();
  };

  return {
    onSendEmail,
  };
};
