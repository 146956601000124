import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InputLogin from '../../../features/input-login/InputLogin';
import { StyledButtonFullBtn, StyledTypography } from './styled';
import { Typography, styled } from '@mui/material';
import { useDispatch } from 'react-redux';
import { clearError } from '../../../entities/AuthSlice';

export const ModalResetPasswordInProcess = ({
  emailForReset,
  handleChangeEmailForReset,
  isEmailForResetValid,
  error,
  setIsResetPasswordMode,
}) => {
  const dispatch = useDispatch();

  const renderErrorMessage = () => {
    if (!error?.isError) return null;

    switch (error.status) {
      case 404:
        return 'Пользователь не найден';
      case 429:
        return 'Превышен лимит запросов';
      default:
        return 'Произошла ошибка. Попробуйте снова.';
    }
  };

  const onClickBackIcon = () => {
    dispatch(clearError());
    setIsResetPasswordMode(false);
  };

  return (
    <>
      <StyledArrowBackIcon onClick={onClickBackIcon} />
      <StyledTypography>Введите e-mail для нового пароля</StyledTypography>
      <InputLogin
        label='Логин (эл. почта)'
        sx={{
          margin: '24px 0 0',
        }}
        value={emailForReset}
        setValue={handleChangeEmailForReset}
      />
      {renderErrorMessage() && (
        <StyledErrorTypography component='div' variant='div' color='error'>
          {renderErrorMessage()}
        </StyledErrorTypography>
      )}
      <StyledButtonFullBtn
        sx={{
          fontSize: '15px !important',
          width: '354px !important',
          backgroundColor: isEmailForResetValid ? '#333130' : '#E0E0E0',
          color: isEmailForResetValid ? '#FFFFFF' : '#989898',
        }}
        type='submit'
        variant='contained'
        color='secondary'
        disabled={!isEmailForResetValid}
      >
        Отправить новый пароль
      </StyledButtonFullBtn>
    </>
  );
};

const StyledErrorTypography = styled(Typography)({
  fontWeight: 400,
  width: '100%',
  marginTop: '10px',
});

const StyledArrowBackIcon = styled(ArrowBackIcon)({
  position: 'absolute',
  marginTop: '18px',
  top: '0px',
  left: '0px',
  cursor: 'pointer',
});
