import { Card, Typography, Box, InputAdornment, styled } from '@mui/material';
import { Colors } from '../../assets/mui-styles/Colors';
import { CustomInput } from './ui/CustomInput';
import { CustomInputWithSlider } from './ui/CustomInputWithSlider';
import { CustomSwitch } from './ui/CustomSwitch';
import { NumberSumFormatDefault } from './ui/InputComponentsForInputWithSlider';
import { toFormat, toFormatDefault } from '../../utils/utils';
import { checkIfCalculatorByFreeNewPropsValid } from '../../utils/calculatorUtils';
import { isGracePeriodInCalculatorForFree } from '../../utils/feature-flags';
import { GracePeriodSection } from './ui/GracePeriodSection';
import { EarlyPaymentSection } from './ui/EarlyPaymentSections';

export const CalculatorForFree = ({
  fetchStatus,
  flatPriceNew,
  minFlatPriceNew,
  maxFlatPriceNew,
  handleChangeFlatPriceNew,
  handleChangeTermNew,
  termForDisplayNew,
  minTermNew,
  maxTermNew,
  rateForDisplayNew,
  minRateNew,
  maxRateNew,
  handleChangeRateNew,
  isPercentNew,
  handleSwitchFirstPaymentType,
  firstPaymentPercentDisplayNew,
  firstPaymentCurrencyDisplayNew,
  handleChangeFirstPaymentNew,
  minFirstPaymentPercentNew,
  maxFirstPaymentPercentNew,
  minFirstPaymentCurrencyNew,
  maxFirstPaymentCurrencyNew,
  earlyPaymentOptions,
  earlyPaymentsForDisplay,
  onChangeEarlyPayment,
  createEmptyPayment,
  deletePayment,
  freeCreditSum,
  firstPaymentCurrencyNewError,
  firstPaymentPercentNewError,
  flatPriceNewError,
  termNewError,
  rateError,
  earlyPaymentsType,
  onChangeEarlyPaymentsType,
  rateNew,
  isGracePeriod,
  toggleGracePeriod,
  gracePercentForDisplay,
  onChangeGracePercent,
  graceTermForDisplay,
  onChangeGraceTerm,
}) => {
  return (
    <>
      <StyledCard>
        <Typography variant='h3' fontSize='28px' fontWeight='500'>
          Рассчитать платеж
        </Typography>
        <StyledContainer>
          <Box width='40%'>
            <CustomInput
              disabled={fetchStatus === 'pending'}
              placeholder='20 000 000'
              helperText={flatPriceNewError}
              error={flatPriceNewError !== ''}
              valueInput={flatPriceNew ? toFormat(flatPriceNew) : ''}
              endAdornment={<InputAdornment position='end'>₽</InputAdornment>}
              label='Стоимость объекта недвижимости'
              inputComponent={NumberSumFormatDefault}
              onChangeInput={value => handleChangeFlatPriceNew(value)}
              fetchStatus={fetchStatus}
            />
          </Box>
          {flatPriceNew >= minFlatPriceNew && flatPriceNew <= maxFlatPriceNew && (
            <>
              <StyledBox>
                <Box sx={{ width: '40%', display: 'flex', flexDirection: 'column' }}>
                  <CustomInputWithSlider
                    fetchStatus={fetchStatus}
                    helperText={isPercentNew ? firstPaymentPercentNewError : firstPaymentCurrencyNewError}
                    error={isPercentNew ? firstPaymentPercentNewError !== '' : firstPaymentCurrencyNewError !== ''}
                    onChangeInput={value => {
                      if (Number.isNaN(value)) return;
                      handleChangeFirstPaymentNew(value);
                    }}
                    onChangeSlider={handleChangeFirstPaymentNew}
                    onChange={value => handleChangeFirstPaymentNew(value)}
                    valueSlider={isPercentNew ? firstPaymentPercentDisplayNew : firstPaymentCurrencyDisplayNew}
                    value={isPercentNew ? firstPaymentPercentDisplayNew : firstPaymentCurrencyDisplayNew}
                    valueInput={
                      isPercentNew ? firstPaymentPercentDisplayNew : toFormatDefault(firstPaymentCurrencyDisplayNew)
                    }
                    label='Сумма первоначального взноса'
                    min={isPercentNew ? minFirstPaymentPercentNew : minFirstPaymentCurrencyNew}
                    max={isPercentNew ? maxFirstPaymentPercentNew : maxFirstPaymentCurrencyNew}
                    minValueText={isPercentNew ? `${minFirstPaymentPercentNew}%` : toFormat(minFirstPaymentCurrencyNew)}
                    maxValueText={isPercentNew ? `${maxFirstPaymentPercentNew}%` : toFormat(maxFirstPaymentCurrencyNew)}
                    endAdornment={
                      <InputAdornment position='end'>
                        {isPercentNew ? toFormat(firstPaymentCurrencyDisplayNew) : `${firstPaymentPercentDisplayNew} %`}
                      </InputAdornment>
                    }
                    onChangeCommitted={handleChangeFirstPaymentNew}
                  />
                  <CustomSwitch
                    onClick={handleSwitchFirstPaymentType}
                    checked={isPercentNew}
                    label='Сумма первого взноса, %'
                    sx={{
                      position: 'relative',
                      right: '10px',
                    }}
                  />
                </Box>
                <Box width='24%'>
                  <CustomInputWithSlider
                    disabled={fetchStatus === 'pending'}
                    helperText={termNewError}
                    error={termNewError !== ''}
                    onChange={e => handleChangeTermNew(e)}
                    onChangeInput={e => {
                      // не даем ввести больше 2 символов
                      const valueLengthValud = Array.from(String(e))?.length <= 2;
                      const valueValid = (e >= minTermNew && e <= maxTermNew) || e === 0;
                      if (valueLengthValud && valueValid) {
                        handleChangeTermNew(e);
                      }
                    }}
                    value={termForDisplayNew}
                    label='Срок кредита'
                    min={minTermNew}
                    max={maxTermNew}
                    minValueText={minTermNew}
                    maxValueText={maxTermNew}
                    inputType='number'
                    endAdornment={<InputAdornment position='end'>лет</InputAdornment>}
                    fetchStatus={fetchStatus}
                  />
                </Box>
                <Box width='32%'>
                  <CustomInputWithSlider
                    disabled={fetchStatus === 'pending'}
                    helperText={rateError}
                    error={rateError !== ''}
                    onChange={handleChangeRateNew}
                    onChangeInput={e => {
                      // не даем ввести больше 3 символов
                      const valueLengthValid = Array.from(String(e))?.length <= 3;
                      const valueValid = (e >= minRateNew && e <= maxRateNew) || e === 0;
                      if (valueLengthValid && valueValid) {
                        handleChangeRateNew(e);
                      }
                    }}
                    value={rateForDisplayNew}
                    label='Процентная ставка'
                    min={minRateNew}
                    max={maxRateNew}
                    minValueText={minRateNew}
                    maxValueText={maxRateNew}
                    inputType='number'
                    endAdornment={<InputAdornment position='end'>%</InputAdornment>}
                    fetchStatus={fetchStatus}
                  />
                </Box>
              </StyledBox>
              {isGracePeriodInCalculatorForFree && (
                <GracePeriodSection
                  isGracePeriod={isGracePeriod}
                  toggleGracePeriod={toggleGracePeriod}
                  gracePercentForDisplay={gracePercentForDisplay}
                  onChangeGracePercent={onChangeGracePercent}
                  graceTermForDisplay={graceTermForDisplay}
                  onChangeGraceTerm={onChangeGraceTerm}
                  termForDisplayNew={termForDisplayNew}
                  fetchStatus={fetchStatus}
                />
              )}
            </>
          )}
        </StyledContainer>
      </StyledCard>
      {checkIfCalculatorByFreeNewPropsValid({
        propsForCalculatorByFreeNew: {
          freeCreditSum,
          flatPriceNew,
          minFlatPriceNew,
          maxFlatPriceNew,
          termNewError,
          rateError,
          flatPriceNewError,
          firstPaymentPercentNewError,
          firstPaymentCurrencyNewError,
          rateNew,
        },
      }) && (
        <EarlyPaymentSection
          onChangeEarlyPaymentsType={onChangeEarlyPaymentsType}
          earlyPaymentOptions={earlyPaymentOptions}
          onChangeEarlyPayment={onChangeEarlyPayment}
          earlyPaymentsType={earlyPaymentsType}
          earlyPaymentsForDisplay={earlyPaymentsForDisplay}
          createEmptyPayment={createEmptyPayment}
          deletePayment={deletePayment}
          fetchStatus={fetchStatus}
        />
      )}
    </>
  );
};

const StyledCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  gap: '22px',
  background: Colors.additional,
  border: '1px solid',
  borderColor: Colors.superLightGrey,
  borderTopColor: 'transparent',
  position: 'relative',
  width: '100%',
  height: '100%',
  padding: '30px 24px',
  ':last-child': {
    marginTop: '20px',
  },
});

const StyledBox = styled(Box)({
  display: 'flex',
  gap: '20px',
  marginTop: '30px',
});

const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});
