import { Card, CardContent, Stack, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { PrimaryColors } from '../../../assets/mui-styles/Colors';
import { initialProjects } from '../../../widgets/calculator/calculator-logic/initialProjects';
import { isFlatSelected } from '../../../utils/utils';

export const EqualizerFlatCardNew = ({
  flat,
  activeProjectId,
  handleChangeActiveProjectId,
  projectHasChanged,
  flatData,
  handleChangeFlat,
  handleChangeSection,
  handleChangeHouse,
  handleChangeBulk,
  handleChangeBenefit,
}) => {
  const currFlatIdFavourite = sessionStorage.getItem('flatFavouriteEqId');
  const project = initialProjects.filter(i => flat.block_id === i.blockId)[0] || [];
  const isSelected = isFlatSelected({ flatData, flat, currFlatIdFavourite, project, activeProjectId });
  const handleClick = () => {
    if (isSelected) return;
    sessionStorage.setItem('flatFavouriteEq', flat.number);
    sessionStorage.setItem('flatFavouriteEqId', flat.id);
    sessionStorage.setItem('projectFavouriteEq', project.value);
    sessionStorage.setItem('activeProjectEq', project.value);

    // сбрасываем программу при смене лота
    handleChangeBenefit();

    if (activeProjectId !== project.value) {
      handleChangeActiveProjectId(project.value);
      projectHasChanged();
    }

    if (project.value === '1') {
      handleChangeSection(String(flat.section.number));
    } else {
      handleChangeBulk(flat.bulk.name);
      handleChangeHouse(flat.bulk.houseName);
    }
    handleChangeFlat({ id: flat?.id, value: flat?.number });
  };

  const textForm = num => {
    if (num === 1) {
      return `${num} спальня`;
    }
    if (num >= 2 && num < 5) {
      return `${num} спальни`;
    }
    if (num === 'studio') {
      return 'студия';
    }
    return `${num} спален`;
  };

  return (
    <StyledCard onClick={handleClick} sx={{ borderColor: isSelected ? PrimaryColors.primary : PrimaryColors.border }}>
      <StyledCardContent>
        {flat.flat_plan_png && (
          <StyledImgWrapper>
            <StyledImg src={flat.flat_plan_png} alt='' />
          </StyledImgWrapper>
        )}

        <Stack direction='column'>
          {flat.number && (
            <Typography variant='body1' color='primary'>
              №{flat.number ? flat.number : ''}
            </Typography>
          )}
          {flat.bedrooms && flat.area && (
            <StyledText variant='subtitle1' color='primary' mt='-2px'>
              {textForm(flat.bedrooms)} {flat.area} м
              <sup>
                <small>2</small>
              </sup>
            </StyledText>
          )}
          {project.title && (
            <Typography variant='subtitle2' color='primary' mt='2px'>
              {project.title}
            </Typography>
          )}
        </Stack>
      </StyledCardContent>
    </StyledCard>
  );
};

const StyledCard = styled(Card)({
  width: 'fit-content',
  height: '93px',
  cursor: 'pointer',
  '@media screen and (min-width: 2560px)': {
    height: '153px',
  },
});

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  boxSizing: 'border-box',
  padding: '15px',
  '@media screen and (min-width: 2560px)': {
    padding: '20px',
  },
});

const StyledImgWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  minWidth: '75px',
  marginRight: '15px',
  '@media screen and (min-width: 2560px)': {
    minWidth: '150px',
    marginRight: '30px',
  },
});

const StyledImg = styled('img')({
  height: '63px',
  '@media screen and (min-width: 2560px)': {
    height: '113px',
  },
});

const StyledText = styled(Typography)({
  '@media screen and (min-width: 2560px)': {
    marginBottom: '21px',
  },
});
