import { Box } from '@mui/system';
import { StyledTypography } from './StyledComponents';
import { toFormat } from '../../../../utils/utils';

const paymentFields = [
  { label: 'Месяц', width: '7%', key: 'month' },
  { label: 'Остаток ссудной задолженности', width: '18%', key: 'remainingPrincipal' },
  { label: 'Проценты', width: '11%', key: 'interest' },
  { label: 'Ссудная задолжность', width: '14%', key: 'debtPayment' },
  { label: 'Платеж', width: '13%', key: 'monthlyPayment' },
  { label: 'Сумма досрочного погашения', width: '13%', key: 'earlyPayment' },
];

export const MonthPaymentRow = ({ payment }) => {
  // Остаток ссудной задолженности
  const remainingPrincipal = `${
    payment?.remainingPrincipal < 0 ? 0 : toFormat(payment?.remainingPrincipal?.toFixed(2)) || 0
  }`;
  // Проценты
  const interest = `${toFormat(payment?.interest) || 0}`;
  // Ссудная задолжность
  const debtPayment = `${toFormat(payment?.debtPayment) || 0}`;
  // Платеж
  const monthlyPayment = `${toFormat(payment?.monthlyPayment) || 0}`;
  // Сумма досрочного платежа
  const earlyPayment = payment?.earlyPayment ? `${toFormat(payment?.earlyPayment)}` : '';

  return (
    <Box sx={{ display: 'flex', marginTop: '21px', justifyContent: 'space-between', alignItems: 'center' }}>
      <StyledTypography width='7%'>{payment?.month || ''}</StyledTypography>
      <StyledTypography width='18%'>{remainingPrincipal}</StyledTypography>
      <StyledTypography width='11%'>{interest}</StyledTypography>
      <StyledTypography width='14%'>{debtPayment}</StyledTypography>
      <StyledTypography width='13%'>{monthlyPayment}</StyledTypography>
      <StyledTypography width='13%'>{earlyPayment}</StyledTypography>
    </Box>
  );
};
export const MonthPaymentRowHeader = () => {
  return (
    <Box sx={{ display: 'flex', marginTop: '21px', justifyContent: 'space-between', alignItems: 'center' }}>
      {paymentFields.map(({ label, width }) => (
        <StyledTypography key={label} width={width} color='#969694'>
          {label}
        </StyledTypography>
      ))}
    </Box>
  );
};
