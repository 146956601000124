import { clearLocalStorageFormData, clearUserData, clearCalculatorStorage } from '../../utils/utils';
import { styled } from '@mui/material';
import { Colors } from '../../assets/mui-styles/Colors';

function ButtonUnlogin({ text }) {
  return (
    <StyledButton
      type={'button'}
      onClick={() => {
        clearUserData(true);
        clearLocalStorageFormData();
        clearCalculatorStorage();
      }}
    >
      <StyledText>{text}</StyledText>

      <StyledIcon width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <path d='M21 12L17 8V11H9V13H17V16L21 12Z' />
        <path d='M5 5H12V3H3V21H12V19H5V5Z' />
      </StyledIcon>
    </StyledButton>
  );
}

export default ButtonUnlogin;

const StyledButton = styled('button')({
  display: 'flex',
  alignItems: 'center',
  padding: '4px 11px',
  color: Colors.secondary,
  backgroundColor: Colors.additional,
  border: `1px solid #c4c4c4`,
  borderRadius: '4px',
  transition: '0.1s',
  cursor: 'pointer',

  '&:hover': {
    color: Colors.primary,
    backgroundColor: Colors.secondary,

    '& svg path': {
      fill: Colors.primary,
    },
  },
});

const StyledText = styled('p')({
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
});

const StyledIcon = styled('svg')({
  marginLeft: '10px',
  fill: Colors.secondary,
  transition: '0.1s',
});
