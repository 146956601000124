import { downloadPdf } from './dataFetchers';
import { formatEarlyPayments } from '../utils/utils';

export const useDownloadPdf = ({ handleSetLoadedStatus, handleSetPendingStatus }) => {
  const downloadPdfHandler = async ({
    propertyPrice,
    initialPayment,
    loanTerm,
    interestRate,
    earlyPayments,
    earlyPaymentsType,
    gracePeriodRate,
    gracePeriodYears,
    isGracePeriod,
  }) => {
    const filteredEarlyPayments = formatEarlyPayments({ earlyPayments, earlyPaymentsType });

    handleSetPendingStatus();

    await downloadPdf({
      propertyPrice,
      initialPayment,
      loanTerm,
      interestRate,
      earlyPayments: filteredEarlyPayments,
      gracePeriodRate: isGracePeriod ? gracePeriodRate : 0,
      gracePeriodYears: isGracePeriod ? gracePeriodYears : 0,
    });

    handleSetLoadedStatus();
  };

  return { downloadPdfHandler };
};
