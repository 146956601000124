import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EqualizerFlatCardNew } from '../equalizer-flat-card/EqualizerFlatCard';
import { v4 as uuidv4 } from 'uuid';
import { useRef } from 'react';
import { useAutoSlideLogic } from './hooks/useAutoSlideLogic';

export const EqualizerFlatsSliderNew = ({ favoriteFlats, propsForFlatCards }) => {
  const currFlatIdFavourite = sessionStorage.getItem('flatFavouriteEqId');
  const swiperRef = useRef(null);

  useAutoSlideLogic({ swiperRef, favoriteFlats, propsForFlatCards, currFlatIdFavourite });

  return (
    <Swiper
      style={{ height: 'auto', width: '100%' }}
      resistance={true}
      resistanceRatio={0}
      preventClicks={true}
      slidesPerView='auto'
      spaceBetween={20}
      centeredSlides={true}
      centeredSlidesBounds={true}
      onSwiper={swiper => (swiperRef.current = swiper)}
    >
      {favoriteFlats.map(flat => (
        <SwiperSlide key={uuidv4()} style={{ width: 'fit-content' }}>
          <EqualizerFlatCardNew {...propsForFlatCards} flat={flat} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
