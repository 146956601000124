import { Stack, Button, Typography, styled } from '@mui/material';
import { Colors } from '../../../assets/mui-styles/Colors';

export const StyledModalAuthStack = styled(Stack)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& input': {
    backgroundColor: Colors.primary,
  },
});

export const StyledButtonFullBtn = styled(Button)(({ width = 'fit-content', disabled = false }) => ({
  height: '56px !important',
  width: width,
  marginTop: '24px !important',
  fontWeight: 300,
  fontSize: '18px !important',
  backgroundColor: disabled ? '#E0E0E0' : '',
  color: disabled ? '#989898' : '',
}));

export const StyledTypography = styled(Typography)({
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '24px',
  color: '#333130',
  width: '100%',
  alignSelf: 'flex-start',
});

export const StyledTitleTypography = styled(Typography)({
  fontWeight: 600,
  fontSize: '28px',
  height: '69px',
});
