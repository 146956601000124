import { Card, styled } from '@mui/material';
import { Colors } from '../../../assets/mui-styles/Colors';
import { useEffect, useCallback } from 'react';
import { StyledModalAuthStack, StyledTitleTypography } from './styled';
import { clearError } from '../../../entities/AuthSlice';
import { useResetPasswordLogic } from '../hooks/useResetPasswordLogic';
import { ModalResetPasswordInProcess } from './ModalResetPasswordInProcess';
import { ModalResetPasswordSuccessful } from './ModalResetPasswordSuccessful';

export const ResetPassword = ({ setIsResetPasswordMode }) => {
  const {
    emailForReset,
    isEmailForResetValid,
    isResetPasswordSuccessful,
    error,
    handleChangeEmailForReset,
    handleCloseModals,
    onSubmit,
    dispatch,
  } = useResetPasswordLogic({ setIsResetPasswordMode });

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      onSubmit(emailForReset);
    },
    [emailForReset, onSubmit],
  );

  useEffect(() => {
    dispatch(clearError());
  }, [dispatch]);

  const modalProps = {
    emailForReset,
    handleCloseModals,
    handleChangeEmailForReset,
    setIsResetPasswordMode,
    error,
    isEmailForResetValid,
  };

  return (
    <StyledModalEmailCard isSuccessful={isResetPasswordSuccessful}>
      <StyledModalAuthStack
        as='form'
        onSubmit={handleSubmit}
        direction='column'
        sx={{
          position: 'relative',
          width: '100%',
        }}
        onKeyDown={e => e.stopPropagation()}
      >
        <StyledTitleTypography
          component='div'
          variant='h4'
          paddingTop={isResetPasswordSuccessful ? '12px' : '18px'}
          color='secondary'
        >
          FORMA
        </StyledTitleTypography>
        {isResetPasswordSuccessful ? (
          <ModalResetPasswordSuccessful {...modalProps} />
        ) : (
          <ModalResetPasswordInProcess {...modalProps} />
        )}
      </StyledModalAuthStack>
    </StyledModalEmailCard>
  );
};

const StyledModalEmailCard = styled(Card)(({ isSuccessful }) => ({
  position: 'absolute !important',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #B4B4B4',
  boxSizing: 'border-box !important',
  width: '100%',
  maxWidth: '420px',
  backgroundColor: Colors.additional,
  borderRadius: '4px',
  transform: 'translate(-50%, -50%)',
  padding: isSuccessful ? '11.5px 32px 20.5px' : '12px 32px',
}));
