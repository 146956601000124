import { ThemeProvider } from '@mui/material';
import { lazy, useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { primaryTheme, secondaryTheme } from './assets/mui-styles/GlobalStyles';
import './assets/scss/globals.scss';
import ROUTES from './entities/api/routes';
import { store } from './entities/store';
import Layout from './layout/Layout';
import AuthPage from './pages/auth-page/AuthPage';
import { CalculatorPageNew } from './pages/calculator-page-new/CalculatorPageNew';
import GuardRoute from './pages/guarded-route/GuardedRoute';
import './utils/muiLicense';
import { ROLES } from './utils/constants.js';
import { accreditationList, partnerPrograms, workingSchedule, RopScheduleActive } from './utils/feature-flags.js';

const MonitorMeetingsPage = lazy(() => import('./pages/monitor-meetings-page/MonitorMeetingsPage'));
const BookingPage = lazy(() => import('./pages/booking-page/BookingPage'));
const BenefitsPage = lazy(() => import('./pages/benefits-page/BenefitsPage'));
const Page403 = lazy(() => import('./pages/403-page/Page403'));
const Page404 = lazy(() => import('./pages/404-page/Page404'));
const LkMainPage = lazy(() => import('./pages/lk-main/LkMain'));
const LotSelectionPage = lazy(() => import('./pages/lot-selection-page/LotSelectionPage'));
const MonitorPage = lazy(() => import('./pages/monitor-page/MonitorPage'));
const WabaDealPage = lazy(() => import('./pages/waba-deal-page/WabaDealPage'));
const WabaDealsPage = lazy(() => import('./pages/waba-deals-page/WabaDealsPage'));
const WabaLeadPage = lazy(() => import('./pages/waba-lead-page/WabaLeadPage'));
const WabaLeadsPage = lazy(() => import('./pages/waba-leads-page/WabaLeadsPage'));
const WabaPage = lazy(() => import('./pages/waba-page/WabaPage'));
const AccreditationTablePage = lazy(() => import('./pages/accreditation-table-page/AccreditationTablePage.jsx'));
const AccreditationPage = lazy(() => import('./pages/accreditation-page/AccreditationPage.jsx'));
const PartnerProgramsPage = lazy(() => import('./pages/partner-programs-page/PartnerProgramsPage.jsx'));
const PartnerProgramsUserPage = lazy(() => import('./pages/partner-programs-page/PartnerProgramsUserPage.jsx'));
const PartnerProgramsCreatedPage = lazy(() => import('./pages/partner-programs-page/PartnerProgramsCreatedPage.jsx'));
const PartnerProgramsUserChangePage = lazy(
  () => import('./pages/partner-programs-page/PartnerProgramsUserChangePage.jsx'),
);
const MeetingsSchedulePage = lazy(() =>
  import('./pages/meetings-schedule-page').then(module => ({
    default: module.MeetingsSchedulePage,
  })),
);
const RopGraphicJobPage = lazy(() => import('./pages/rop-graphic-job-page/RopGraphicJobPage'));

const App = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [userAgent, setUserAgent] = useState(navigator.userAgent);
  const [vendor] = useState(navigator.vendor);

  const handleChangeWidth = () => {
    const delta = width - window.innerWidth;
    if (delta > 80 || delta < -80) {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleChangeWidth);
    window.addEventListener('orientationchange', handleChangeWidth);

    setUserAgent(navigator.userAgent);
  }, []);
  return (
    <Provider store={store}>
      <HelmetProvider>
        <ThemeProvider theme={secondaryTheme}>
          <Routes>
            <Route
              path={ROUTES.root}
              element={<Layout vendor={vendor} width={width} userAgent={userAgent} height={height} />}
            >
              <Route index element={<AuthPage />} />
              <Route
                path={ROUTES.calculator}
                element={
                  <GuardRoute
                    allowedRoles={[
                      ROLES.ADMIN,
                      ROLES.MANAGER,
                      ROLES.OPENLIST_MANAGER,
                      ROLES.MORTGAGE_MANAGER,
                      ROLES.LEAD_MANAGER,
                    ]}
                    Component={<CalculatorPageNew />}
                  />
                }
              />
              <Route
                path={ROUTES.monitor}
                element={<GuardRoute allowedRoles={[ROLES.ADMIN, ROLES.MONITOR_MANAGER]} Component={<MonitorPage />} />}
              />
              <Route
                path={ROUTES.meetingsMonitor}
                element={
                  <GuardRoute
                    allowedRoles={[ROLES.ADMIN, ROLES.MONITOR_MANAGER]}
                    Component={
                      <ThemeProvider theme={primaryTheme}>
                        <MonitorMeetingsPage />
                      </ThemeProvider>
                    }
                  />
                }
              />
              <Route
                path={ROUTES.lotSelection}
                element={
                  <GuardRoute allowedRoles={[ROLES.ADMIN, ROLES.PDF_MANAGER]} Component={<LotSelectionPage />} />
                }
              />
              <Route path={ROUTES.forbidden} element={<Page403 />} />
              <Route path='*' element={<Page404 />} />
              <Route
                path={ROUTES.lk.root}
                element={
                  <ThemeProvider theme={primaryTheme}>
                    <GuardRoute
                      allowedRoles={[
                        ROLES.ADMIN,
                        ROLES.OFFICE_MANAGER,
                        ROLES.MANAGER,
                        ROLES.OPENLIST_MANAGER,
                        ROLES.COMMERCIAL_MANAGER,
                        ROLES.MORTGAGE_MANAGER,
                      ]}
                      Component={
                        <LkMainPage
                          allowedRoles={[
                            ROLES.ADMIN,
                            ROLES.OFFICE_MANAGER,
                            ROLES.MANAGER,
                            ROLES.OPENLIST_MANAGER,
                            ROLES.COMMERCIAL_MANAGER,
                            ROLES.MORTGAGE_MANAGER,
                          ]}
                        />
                      }
                    />
                  </ThemeProvider>
                }
              />
              <Route
                path={ROUTES.booking}
                element={
                  <ThemeProvider theme={primaryTheme}>
                    <GuardRoute Component={<BookingPage />} allowedRoles={[ROLES.ADMIN, ROLES.MANAGER]} />
                  </ThemeProvider>
                }
              />
              <Route
                path={ROUTES.waba.root}
                element={<GuardRoute Component={<WabaPage />} allowedRoles={[ROLES.ADMIN, ROLES.MANAGER]} />}
              />
              <Route
                path={ROUTES.waba.leads}
                element={<GuardRoute Component={<WabaLeadsPage />} allowedRoles={[ROLES.ADMIN, ROLES.MANAGER]} />}
              />
              <Route
                path={ROUTES.waba.lead}
                element={<GuardRoute Component={<WabaLeadPage />} allowedRoles={[ROLES.ADMIN, ROLES.MANAGER]} />}
              />
              <Route
                path={ROUTES.waba.deals}
                element={<GuardRoute Component={<WabaDealsPage />} allowedRoles={[ROLES.ADMIN, ROLES.MANAGER]} />}
              />
              <Route
                path={ROUTES.waba.deal}
                element={<GuardRoute Component={<WabaDealPage />} allowedRoles={[ROLES.ADMIN, ROLES.MANAGER]} />}
              />
              <Route
                path={ROUTES.benefits}
                element={
                  <GuardRoute allowedRoles={[ROLES.ADMIN, ROLES.BENEFITS_MANAGER]} Component={<BenefitsPage />} />
                }
              />
              {/* Страница таблицы аккредитации */}
              {accreditationList && (
                <Route
                  path={ROUTES.accreditation}
                  element={
                    <ThemeProvider theme={primaryTheme}>
                      <GuardRoute
                        allowedRoles={[ROLES.ADMIN, ROLES.ACRED_MANAGER]}
                        Component={<AccreditationTablePage />}
                      />
                    </ThemeProvider>
                  }
                />
              )}
              {/* Страница заявки аккредитации */}
              {accreditationList && (
                <Route
                  path={ROUTES.accreditationApplication}
                  element={
                    <ThemeProvider theme={primaryTheme}>
                      <GuardRoute allowedRoles={[ROLES.ADMIN, ROLES.ACRED_MANAGER]} Component={<AccreditationPage />} />
                    </ThemeProvider>
                  }
                />
              )}
              {/* Страница партнерских программ (таблица всех программ)*/}
              {partnerPrograms && (
                <Route
                  path={ROUTES.partner.root}
                  element={
                    <ThemeProvider theme={primaryTheme}>
                      <GuardRoute
                        allowedRoles={[ROLES.ADMIN, ROLES.PARTNER_MANAGER]}
                        Component={<PartnerProgramsPage />}
                      />
                    </ThemeProvider>
                  }
                />
              )}
              {/* Страница партнерских программ (отдельная страница программы)*/}
              {partnerPrograms && (
                <Route
                  path={ROUTES.partner.partnerShow}
                  element={
                    <ThemeProvider theme={primaryTheme}>
                      <GuardRoute
                        allowedRoles={[ROLES.ADMIN, ROLES.PARTNER_MANAGER]}
                        Component={<PartnerProgramsUserPage />}
                      />
                    </ThemeProvider>
                  }
                />
              )}
              {/* Страница партнерских программ (отдельная страница редактирования программы)*/}
              {partnerPrograms && (
                <Route
                  path={ROUTES.partner.partnerChange}
                  element={
                    <ThemeProvider theme={primaryTheme}>
                      <GuardRoute
                        allowedRoles={[ROLES.ADMIN, ROLES.PARTNER_MANAGER]}
                        Component={<PartnerProgramsUserChangePage />}
                      />
                    </ThemeProvider>
                  }
                />
              )}
              {/* Страница партнерских программ (создание программы*/}
              {partnerPrograms && (
                <Route
                  path={ROUTES.partner.partner_created}
                  element={
                    <ThemeProvider theme={primaryTheme}>
                      <GuardRoute
                        allowedRoles={[ROLES.ADMIN, ROLES.PARTNER_MANAGER]}
                        Component={<PartnerProgramsCreatedPage />}
                      />
                    </ThemeProvider>
                  }
                />
              )}

              {workingSchedule && (
                <Route
                  path={ROUTES.meetingSchedule}
                  element={
                    <GuardRoute
                      allowedRoles={[ROLES.ADMIN, ROLES.MANAGER, ROLES.LEAD_MANAGER, ROLES.CALLCENTER_MANAGER]}
                      Component={
                        <ThemeProvider theme={primaryTheme}>
                          <MeetingsSchedulePage />
                        </ThemeProvider>
                      }
                    />
                  }
                />
              )}

              {RopScheduleActive && (
                <Route
                  path={ROUTES.ropSchedule}
                  element={
                    <GuardRoute
                      allowedRoles={[ROLES.LEAD_MANAGER, ROLES.ADMIN]}
                      Component={
                        <ThemeProvider theme={primaryTheme}>
                          <RopGraphicJobPage />
                        </ThemeProvider>
                      }
                    />
                  }
                />
              )}
            </Route>
          </Routes>
        </ThemeProvider>
      </HelmetProvider>
    </Provider>
  );
};

export default App;
