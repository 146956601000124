import { createSlice } from '@reduxjs/toolkit';
import ROUTES from './api/routes';

export const initialState = [
  {
    id: 0,
    disabled: false,
    isActive: true,
    icon: 'HomeOutlinedIcon',
    link: ROUTES.lk.meetings,
  },
  {
    id: 1,
    disabled: false,
    isActive: false,
    icon: 'PersonPinOutlinedIcon',
    link: ROUTES.lk.form,
  },
  {
    id: 2,
    disabled: false,
    isActive: false,
    icon: 'SlideshowOutlinedIcon',
    link: ROUTES.lk.presentation,
  },
  {
    id: 3,
    disabled: false,
    isActive: false,
    icon: 'HorizontalSplitOutlinedIcon',
    link: ROUTES.lk.lkProjects,
  },
  {
    id: 4,
    disabled: false,
    isActive: false,
    icon: 'CalculateOutlinedIcon',
    link: ROUTES.lk.equalizer,
  },
  {
    id: 5,
    disabled: false,
    isActive: false,
    icon: 'FavoriteBorderOutlinedIcon',
    link: ROUTES.lk.favorites,
  },
  {
    id: 6,
    disabled: false,
    isActive: false,
    icon: 'PictureAsPdfOutlinedIcon',
    link: ROUTES.lk.final,
  },
  {
    id: 7,
    disabled: false,
    isActive: false,
    icon: 'FmdGoodOutlined',
    link: ROUTES.lk.map,
  },
];

const sideMenuSlice = createSlice({
  name: 'sideMenu',
  initialState,
  reducers: {
    setCommercialManagerLink(state, action) {
      const { isCommercialManager } = action.payload;
      return state.map(link => {
        if (isCommercialManager && link.id === 3) {
          return { ...link, link: ROUTES.lk.lkProjectsWorkplace }; // Меняем ссылку для коммерческого менеджера
        }
        return link;
      });
    },

    meetingSetup(state) {
      return state.map(link => (link?.id === 0 ? { ...link, disabled: true } : link));
    },
    openlistMeetingSetup(state) {
      return state.map(link =>
        link?.id === 0 || link?.id === 1 ? { ...link, disabled: true } : { ...link, disabled: false },
      );
    },
    commercialMeetingSetup(state, action) {
      const { savedPath } = action.payload;

      if (savedPath === ROUTES.lk.form) {
        return state.map(link =>
          link.id === 1 || link.id === 7 ? { ...link, disabled: false } : { ...link, disabled: true },
        );
      }

      if (savedPath === ROUTES.lk.presentation) {
        return state.map(link =>
          link.id === 1 || link.id === 2 || link.id === 7 ? { ...link, disabled: false } : { ...link, disabled: true },
        );
      }

      if (savedPath === ROUTES.lk.lkProjects) {
        return state.map(link =>
          link.id !== 5 && link.id !== 0 && link.id !== 4 ? { ...link, disabled: false } : { ...link, disabled: true },
        );
      }

      return state;
    },
    mortgageMeetingSetup(state) {
      return state.map(link =>
        link?.id === 0 || link?.id === 1 || link?.id === 2 ? { ...link, disabled: true } : { ...link, disabled: false },
      );
    },
    setLink(state, action) {
      return state.map(link => ({
        ...link,
        isActive: link?.id === action.payload.id,
      }));
    },
    openFavorites(state) {
      return state.map(link => (link?.id === 5 ? { ...link, disabled: false, isActive: false } : link));
    },
    closeFavorites(state) {
      return state.map(link => (link?.id === 5 ? { ...link, disabled: true } : link));
    },
    zeroStage(state) {
      return state.map(link => (link?.id === 0 ? { ...link, disabled: false } : { ...link, disabled: true }));
    },
    firstStage(state) {
      return state.map(link =>
        link?.id === 1 || link?.id === 7 ? { ...link, disabled: false } : { ...link, disabled: true },
      );
    },
    secondStage(state) {
      return state.map(link =>
        link?.id === 1 || link?.id === 2 || link?.id === 7 ? { ...link, disabled: false } : { ...link, disabled: true },
      );
    },
    thirdStage(state) {
      return state.map(link =>
        link?.id !== 5 && link?.id !== 0 && link?.id !== 4 ? { ...link, disabled: false } : { ...link, disabled: true },
      );
    },
    closeStageForPWA(state) {
      return state.map(link =>
        link?.id === 1 || link?.id === 2 ? { ...link, disabled: false } : { ...link, disabled: true },
      );
    },
    enableManagerLinks(state) {
      return state.map(link => (link?.id !== 0 ? { ...link, disabled: false } : { ...link, disabled: true }));
    },
  },
});

export const {
  setCommercialManagerLink,
  meetingSetup,
  openlistMeetingSetup,
  commercialMeetingSetup,
  mortgageMeetingSetup,
  setLink,
  openFavorites,
  closeFavorites,
  zeroStage,
  firstStage,
  secondStage,
  thirdStage,
  closeStageForPWA,
  enableManagerLinks,
} = sideMenuSlice.actions;

export default sideMenuSlice.reducer;

export const selectSideMenuLinks = state => state.sideMenu;
