import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { InputAdornment } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useState } from 'react';
import style from './InputPassword.module.scss';

function InputPassword({ value, setValue, isError = false }) {
  const handleChangeValue = event => {
    setValue(event.target.value);
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const isShowPassword = showPassword ? <Visibility /> : <VisibilityOff />;

  const passwordIcon = value ? isShowPassword : null;

  return (
    <FormControl className={style.inputPasswordInput} variant='outlined' error={isError}>
      <InputLabel
        color={isError ? 'error' : 'secondary'}
        htmlFor='outlined-adornment-password'
        sx={{ color: isError ? 'error.main' : 'inherit' }}
      >
        Пароль
      </InputLabel>
      <OutlinedInput
        id='outlined-adornment-password'
        type={showPassword ? 'text' : 'password'}
        value={value}
        error={isError}
        onChange={handleChangeValue}
        color={isError ? 'error' : 'secondary'}
        autoComplete='on'
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge='end'
            >
              {passwordIcon}
            </IconButton>
          </InputAdornment>
        }
        label='Пароль'
      />
    </FormControl>
  );
}

export default InputPassword;
