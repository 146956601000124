import ROUTES from '../entities/api/routes';
import { accreditationList, partnerPrograms, workingSchedule, bookingRedirectToPartner } from './feature-flags';

export const swBroadcast = new BroadcastChannel('sw-channel');
export const DOMAIN_RELATIVE = import.meta.env.VITE_REACT_APP_DOMAIN;
export const CDN = 'https://cdn.forma.ru/plans';
export const BlockIds = {
  REPUBLIC_BLOCK_ID: 427,
  SOUL_BLOCK_ID: 1246,
  PORTLAND_BLOCK_ID: 1420,
  FORST_BLOCK_ID: 380,
  MOMENTS_BLOCK_ID: 1457,
  PORTA_BLOCK_ID: 2114,
  FRAME_BLOCK_ID: 2157,
  PAVE_BLOCK_ID: 2168,
};

export const BulkIds = {
  UNIQUE_BULK_ID: 9919,
  REDS_BULK_ID: 8995,
  PLATINUM_BULK_ID: 9390,
  GREEN_BULK_ID: 10322,
  GOLD_BULK_ID: 10323,
};

console.log(`Using ${DOMAIN_RELATIVE} domain`);

export const ROLES = {
  OFFICE_MANAGER: 'office-manager',
  MANAGER: 'manager',
  OPENLIST_MANAGER: 'openlist-manager',
  COMMERCIAL_MANAGER: 'commercial-manager',
  ADMIN: 'admin',
  MORTGAGE_MANAGER: 'mortgage-manager',
  MONITOR_MANAGER: 'monitor-manager',
  PDF_MANAGER: 'pdf-manager',
  BENEFITS_MANAGER: 'benefits-manager',
  ACRED_MANAGER: 'accreditation-manager',
  PARTNER_MANAGER: 'partner-manager',
  LEAD_MANAGER: 'lead-manager',
  CALLCENTER_MANAGER: 'callcenter-manager',
};

export const TABLE_API = {
  tabs: [
    {
      id: 1,
      text: 'Информация о лоте',
    },
    {
      id: 2,
      text: 'Сводная по программам',
    },
    {
      id: 3,
      text: 'Benefits',
    },
    {
      id: 4,
      text: 'Цена по Benefits',
    },
    {
      id: 5,
      text: 'Даты старта по Benefits',
    },
    {
      id: 6,
      text: 'Характеристики',
    },
  ],
  headDefault: [
    {
      id: 9,
      name: 'Номер лота',
      text: 'Номер&nbspлота',
      sort: true,
    },
    {
      id: 10,
      name: 'Общая площадь',
      text: 'Общая площадь',
      sort: true,
    },
    {
      id: 1,
      name: 'Проект',
      text: 'Проект',
    },
    {
      id: 2,
      name: 'Корпус',
      text: 'Корпус',
    },
    {
      id: 3,
      name: 'ID корпуса',
      text: 'ID&nbspкорпуса',
      sort: true,
    },
    {
      id: 4,
      name: 'ОН',
      text: 'ОН',
    },
    {
      id: 5,
      name: 'ID',
      text: 'ID',
      sort: true,
    },
    {
      id: 6,
      name: 'GUID',
      text: 'GUID',
    },
    {
      id: 7,
      name: 'Ссылка на ОН в CRM',
      text: 'Ссылка на&nbspОН&nbspв&nbspCRM',
    },
    {
      id: 8,
      name: 'Ссылка на сайте',
      text: 'Ссылка на&nbspсайте',
    },
  ],
  headFirst: [
    {
      id: 101,
      name: 'Секция',
      text: 'Секция',
    },
    {
      id: 102,
      name: 'Этаж',
      text: 'Этаж',
    },
    {
      id: 103,
      name: 'Количество этажей в секции',
      text: 'Количество этажей&nbspв&nbspсекции',
    },
    {
      id: 104,
      name: 'Студия',
      text: 'Студия',
    },
    {
      id: 105,
      name: 'Комнатность',
      text: 'Комнатность',
      sort: true,
    },
    {
      id: 106,
      name: 'Спальни',
      text: 'Спальни',
      sort: true,
    },
    {
      id: 107,
      name: 'Тип ОН',
      text: 'Тип&nbspОН',
    },
    {
      id: 108,
      name: 'Статус продажи',
      text: 'Статус продажи',
    },
    {
      id: 109,
      name: "Дата статуса 'свободно'",
      text: 'Дата&nbspстатуса "свободно"',
    },
    {
      id: 110,
      name: 'Стоимость бронирования',
      text: 'Стоимость бронирования',
    },
    {
      id: 111,
      name: 'Прайсовая стоимость',
      text: 'Прайсовая стоимость',
      sort: true,
    },
    {
      id: 112,
      name: 'Ввод в эксплуатацию',
      text: 'Ввод&nbspв&nbspэксплуатацию',
    },
    {
      id: 113,
      name: 'Дата передачи ключей',
      text: 'Дата&nbspпередачи&nbspключей',
    },
  ],
  headSecond: [
    {
      id: 201,
      name: 'Кол-во отображаемых программ',
      text: 'Кол-во отображаемых&nbspпрограмм',
    },
    {
      id: 202,
      name: 'Кол-во Cash программ (наличные)',
      text: 'Кол-во&nbspCash программ&nbsp(наличные)',
    },
    {
      id: 203,
      name: 'Кол-во Mortgage программ (ипотека)',
      text: 'Кол-во&nbspMortgage программ&nbsp(ипотека)',
    },
    {
      id: 204,
      name: 'Кол-во Installment программ (рассрочка)',
      text: 'Кол-во&nbspInstallment программ&nbsp(рассрочка)',
    },
  ],
  headBenefits: [
    {
      id: 301,
      name: 'Стоимость по главной льготе',
      text: 'Стоимость по&nbspглавной&nbspльготе',
      sort: true,
    },
    {
      id: 302,
      name: 'Льгота с акцией',
      text: 'Льгота с&nbspакцией',
    },
    {
      id: 303,
      name: 'Главная льгота',
      text: 'Главная&nbspльгота',
    },
    {
      id: 304,
      name: 'Стоимость по акции',
      text: 'Стоимость по&nbspакции',
    },
  ],
  headSixth: [
    {
      id: 601,
      name: 'Вид во двор (vidVoDvor)',
      text: 'Вид во&nbspдвор (vidVoDvor)',
      value: 'vidVoDvor',
    },
    {
      id: 602,
      name: 'Вид на реку (vidNaReku)',
      text: 'Вид на&nbspреку (vidNaReku)',
      value: 'vidNaReku',
    },
    {
      id: 603,
      name: 'Вид на Юг (roomOnSouth)',
      text: 'Вид на&nbspЮг (roomOnSouth)',
      value: 'roomOnSouth',
    },
    {
      id: 604,
      name: 'Вид на Восток (roomOnEast)',
      text: 'Вид на&nbspВосток (roomOnEast)',
      value: 'roomOnEast',
    },
    {
      id: 605,
      name: 'Вид на Запад (roomOnWest)',
      text: 'Вид на&nbspЗапад (roomOnWest)',
      value: 'roomOnWest',
    },
    {
      id: 606,
      name: 'Вид на Север (roomOnNorth)',
      text: 'Вид на&nbspСевер (roomOnNorth)',
      value: 'roomOnNorth',
    },
    {
      id: 607,
      name: 'Окна на 3 стороны (oknaNa3Storony)',
      text: 'Окна на&nbsp3&nbspстороны (oknaNa3Storony)',
      value: 'oknaNa3Storony',
    },
    {
      id: 608,
      name: 'Окна на 2 стороны (oknaNa2Storony)',
      text: 'Окна на&nbsp2&nbspстороны (oknaNa2Storony)',
      value: 'oknaNa2Storony',
    },
    {
      id: 609,
      name: 'Количество санузлов (countOfBathrooms)',
      text: 'Количество санузлов (countOfBathrooms)',
      value: 'countOfBathrooms',
    },
    {
      id: 610,
      name: 'Не первый этаж (notfirstfloor)',
      text: 'Не&nbspпервый&nbspэтаж (notfirstfloor)',
      value: 'notfirstfloor',
    },
    {
      id: 611,
      name: 'Прачечная (laundry)',
      text: 'Прачечная (laundry)',
      value: 'laundry',
    },
    {
      id: 612,
      name: 'Гардеробная (dressingRoom)',
      text: 'Гардеробная (dressingRoom)',
      value: 'dressingRoom',
    },
    {
      id: 613,
      name: 'Ванная с окном (oknoVVannoj)',
      text: 'Ванная с&nbspокном (oknoVVannoj)',
      value: 'oknoVVannoj',
    },
    {
      id: 614,
      name: 'Ванная (bath)',
      text: 'Ванная (bath)',
      value: 'bath',
    },
    {
      id: 615,
      name: 'Лоджия (loggia)',
      text: 'Лоджия (loggia)',
      value: 'loggia',
    },
    {
      id: 616,
      name: 'Балкон (balcony)',
      text: 'Балкон (balcony)',
      value: 'balcony',
    },
    {
      id: 617,
      name: 'Мастер-спальня (masterBedroom)',
      text: 'Мастер-спальня (masterBedroom)',
      value: 'masterBedroom',
    },
    {
      id: 618,
      name: 'Французский балкон (frenchBalcony)',
      text: 'Французский балкон (frenchBalcony)',
      value: 'frenchBalcony',
    },
    {
      id: 619,
      name: 'Угловое остекление (cornerGlazing)',
      text: 'Угловое остекление (cornerGlazing)',
      value: 'cornerGlazing',
    },
    {
      id: 620,
      name: 'Панорамное остекление (panoramicGlazing)',
      text: 'Панорамное остекление (panoramicGlazing)',
      value: 'panoramicGlazing',
    },
    {
      id: 621,
      name: 'Кухня-гостиная (livingKitchen)',
      text: 'Кухня-гостиная (livingKitchen)',
      value: 'livingKitchen',
    },
    {
      id: 622,
      name: 'Без отделки (unfinished)',
      text: 'Без&nbspотделки (unfinished)',
      value: 'unfinished',
    },
    {
      id: 623,
      name: 'Без отделки (woWhitebox)',
      text: 'Без&nbspотделки (woWhitebox)',
      value: 'woWhitebox',
    },
    {
      id: 624,
      name: 'Отделка White Box Max (предчистовая) (whiteBoxMax)',
      text: 'Отделка White&nbspBox&nbspMax (предчистовая) (whiteBoxMax)',
      value: 'whiteBoxMax',
    },
    {
      id: 625,
      name: 'Отделка White Box (предчистовая) (whiteBox)',
      text: 'Отделка White&nbspBox (предчистовая) (whiteBox)',
      value: 'whiteBox',
    },
    {
      id: 626,
      name: 'Чистовая отделка (finish)',
      text: 'Чистовая отделка (finish)',
      value: 'finish',
    },
    {
      id: 627,
      name: 'Потолок выше 3х метров (highCeiling)',
      text: 'Потолок выше&nbsp3х&nbspметров (highCeiling)',
      value: 'highCeiling',
    },
    {
      id: 628,
      name: 'Пентхаус (penthaus)',
      text: 'Пентхаус (penthaus)',
      value: 'penthaus',
    },
    {
      id: 629,
      name: 'Камин (kamin)',
      text: 'Камин (kamin)',
      value: 'kamin',
    },
    {
      id: 630,
      name: 'Сауна (sauna)',
      text: 'Сауна (sauna)',
      value: 'sauna',
    },
    {
      id: 631,
      name: 'Патио (patio)',
      text: 'Патио (patio)',
      value: 'patio',
    },
  ],
};

export const ATTRIBUTES = {
  '1OdinPanoramnyjBalkonVKvartire': '1 Панорамный балкон',
  '2DvaPanoramnyhBalkonaVKvartire': '2 Панорамных балкона',
  balcony: 'Балкон',
  bath: 'Ванная',
  cornerGlazing: 'Угловое остекление',
  countOfBathrooms: 'Количество санузлов',
  dressingRoom: 'Гардеробная',
  finish: 'Чистовая отделка',
  frenchBalcony: 'Французский балкон',
  highCeiling: 'Высокие потолки (выше 3 м)',
  kamin: 'Камин',
  kabinet: 'Кабинет',
  laundry: 'Прачечная',
  livingKitchen: 'Кухня-гостиная',
  loggia: 'Лоджия',
  masterBedroom: 'Мастер-спальня',
  notfirstfloor: 'Не первый этаж',
  oknaNa2Storony: 'Окна на 2 стороны',
  oknaNa3Storony: 'Окна на 3 стороны',
  oknoVVannoj: 'Ванная с окном',
  panoramicGlazing: 'Панорамное остекление',
  patio: 'Патио',
  penthaus: 'Пентхаус',
  roomOnEast: 'Вид на Восток',
  roomOnNorth: 'Вид на Север',
  roomOnSouth: 'Вид на Юг',
  roomOnWest: 'Вид на Запад',
  sauna: 'Cауна',
  terrasa: 'Терраса',
  uglovoeOsteklenie: 'Угловое остекление',
  unfinished: 'Без отделки',
  vidNaPark: 'Вид на парк',
  vidNaReku: 'Вид на реку',
  vidVoDvor: 'Вид во двор',
  whiteBox: 'Отделка White Box',
  whiteBoxMax: 'Отделка White Box Max',
  woWhitebox: 'Без отделки',
};

export const WORD_ENDINGS = {
  roomsEndings: {
    firstState: 'спален',
    secondState: 'спальня',
    thirdState: 'спальни',
    fourthState: 'спален',
  },
};

export const USER_TOOLS = [
  {
    title: 'Калькулятор программ',
    text: 'Расчет ежемесячной стоимости, первого взноса и срока ипотеки',
    link: '/calculator',
    role: [ROLES.ADMIN, ROLES.MANAGER, ROLES.OPENLIST_MANAGER, ROLES.MORTGAGE_MANAGER, ROLES.LEAD_MANAGER],
    onlyMobile: false,
  },
  {
    title: 'Управление бенефитами',
    text: 'Редактировать название и описания льгот на сайте',
    link: '/benefits',
    role: [ROLES.ADMIN, ROLES.BENEFITS_MANAGER],
  },
  {
    title: 'Мониторинг характеристик  и льгот',
    text: 'Отслеживание информации',
    link: '/monitor',
    role: [ROLES.ADMIN, ROLES.MONITOR_MANAGER],
    onlyMobile: false,
  },
  {
    title: 'Экспорт PDF',
    text: 'Формирование PDF с планировками для всех квартир по проектам.',
    link: '/lot-selection',
    role: [ROLES.ADMIN, ROLES.PDF_MANAGER],
    onlyMobile: false,
  },
  {
    title: 'Шаблоны WA',
    text: 'Отправка шаблонов WA через Битрикс24.',
    link: '/waba',
    role: [ROLES.ADMIN, ROLES.MANAGER],
    onlyMobile: true,
  },
  {
    title: 'Панель менеджера',
    text: 'Подбор квартиры и программы оплаты для клиента',
    link: '/lk',
    role: [
      ROLES.ADMIN,
      ROLES.OFFICE_MANAGER,
      ROLES.MANAGER,
      ROLES.OPENLIST_MANAGER,
      ROLES.COMMERCIAL_MANAGER,
      ROLES.MORTGAGE_MANAGER,
    ],
    onlyMobile: false,
  },
  {
    title: 'Бронирование',
    text: 'Формирование уникальной ссылки на бронирование',
    link: bookingRedirectToPartner ? 'https://partner.forma.ru/booking' : '/booking',
    role: [ROLES.ADMIN, ROLES.MANAGER],
    onlyMobile: false,
  },
  {
    title: 'Мониторинг встреч',
    text: 'Аналитика встреч',
    link: '/monitor-meetings',
    role: [ROLES.ADMIN, ROLES.MONITOR_MANAGER],
    onlyMobile: false,
  },
  workingSchedule && {
    title: 'График встреч',
    text: 'Аналитика встреч',
    link: ROUTES.meetingSchedule,
    role: [ROLES.ADMIN, ROLES.MANAGER, ROLES.LEAD_MANAGER, ROLES.CALLCENTER_MANAGER],
    onlyMobile: false,
  },
  accreditationList && {
    title: 'Заявки на аккредитацию',
    text: 'Проверка заявок, которые оставили новые партнеры',
    link: '/acred',
    role: [ROLES.ADMIN, ROLES.ACRED_MANAGER],
    onlyMobile: false,
  },
  partnerPrograms && {
    title: 'Партнерские программы',
    text: ' Управление партнерами компании',
    link: '/partner-admin',
    role: [ROLES.ADMIN, ROLES.PARTNER_MANAGER],
    onlyMobile: false,
  },
]
  .filter(Boolean)
  .map((r, idx) => ({ id: idx, ...r }));

export const MEET_STATES = {
  CLIENT_IN_OFFICE: 'C7:UC_24158U',
  APPROVED: 'C7:EXECUTING',
  NOT_APPROVED: 'C7:PREPAYMENT_INVOICE',
};

export const phoneNumberMask = [
  '+',
  '7',
  ' ',
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

const localPartPattern = /^[^\s@]+/; // Локальная часть (до @)
const atSymbolPattern = /@/; // Символ @
const domainPartPattern = /[^\s@]+/; // Доменная часть (после @)
const topLevelDomainPattern = /\.[^\s@]+$/; // Часть после точки в домене

export const emailPattern = new RegExp(
  `${localPartPattern.source}${atSymbolPattern.source}${domainPartPattern.source}${topLevelDomainPattern.source}`,
);
