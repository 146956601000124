import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { Button, styled } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  calcCompareDataSelector,
  compareDataCheckSelector,
  fetchCalcCompareData,
  fetchCompareCalcCheck,
  fetchDeleteCompareData,
} from '../../../entities/EqualizerSlice';
import { useGetFavoritesFlatsQuery } from '../../../entities/FavoritesSlice';
import { EqualizerBookButton } from '../equalizer-book-button/EqualizerBookButton';

export const EqualizerCompareButton = ({ currentBenefit, meetingId, loanTerm, firstPaymentCommitted, flatData }) => {
  const dispatch = useDispatch();
  const compareCheck = useSelector(compareDataCheckSelector);
  const calcCompareData = useSelector(calcCompareDataSelector);
  const benefitId = currentBenefit.id;
  const { refetch } = useGetFavoritesFlatsQuery(meetingId);

  const handleAddCompare = () => {
    const data = {
      flatId: Number(flatData?.id),
      benefitId: Number(benefitId),
      initialPayment: firstPaymentCommitted,
      loanTerm: Number(loanTerm),
      flat_data: flatData,
    };
    dispatch(fetchCalcCompareData({ data, meetingId })).finally(() => refetch());
  };

  const handleRemoveCompare = () => {
    dispatch(fetchDeleteCompareData({ benefitId, meetingId }));
  };

  useEffect(() => {
    if (flatData?.id) {
      dispatch(
        fetchCompareCalcCheck({
          meetingId,
          loanTerm,
          initialPayment: firstPaymentCommitted,
          benefitId,
          flatId: flatData?.id,
        }),
      );
    }
  }, [benefitId, dispatch, flatData?.id, loanTerm, meetingId, calcCompareData, firstPaymentCommitted]);

  return (
    <>
      {!compareCheck?.result && (
        <StyledButton onClick={handleAddCompare} variant='text'>
          Добавить программу в сравнение
          <PlaylistAddIcon />
        </StyledButton>
      )}

      {compareCheck?.result && (
        <StyledButton onClick={handleRemoveCompare} variant='text'>
          Программа добавлена в сравнение
          <PlaylistAddCheckIcon />
        </StyledButton>
      )}
      {flatData?.bookingStatus === 'active' && (
        <EqualizerBookButton
          benefit={currentBenefit}
          firstPayment={firstPaymentCommitted}
          flatData={flatData}
          term={loanTerm}
        />
      )}
    </>
  );
};

const StyledButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  textTransform: 'none',
  '& svg': {
    width: '20px',
    height: '20px',
    marginLeft: '8px',
  },
});
