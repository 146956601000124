export const checkIfCalculatorByFreeNewPropsValid = ({ propsForCalculatorByFreeNew }) => {
  const creditSumValid = propsForCalculatorByFreeNew?.freeCreditSum > 0;
  const isFlatPriceExists = propsForCalculatorByFreeNew?.flatPriceNew > 0;
  const isFlatPriceValid =
    propsForCalculatorByFreeNew?.flatPriceNew >= propsForCalculatorByFreeNew?.minFlatPriceNew &&
    propsForCalculatorByFreeNew?.flatPriceNew <= propsForCalculatorByFreeNew?.maxFlatPriceNew;
  const isRateExists = propsForCalculatorByFreeNew?.rateNew > 0;
  const termError = propsForCalculatorByFreeNew?.termNewError !== '';
  const rateError = propsForCalculatorByFreeNew?.rateError !== '';
  const flatPriceError = propsForCalculatorByFreeNew?.flatPriceNewError !== '';
  const firstPaymentPercentError = propsForCalculatorByFreeNew?.firstPaymentPercentNewError !== '';
  const firstPaymentCurrencyNewError = propsForCalculatorByFreeNew?.firstPaymentCurrencyNewError !== '';
  const graceTermValid = propsForCalculatorByFreeNew?.isGracePeriod
    ? propsForCalculatorByFreeNew?.graceTerm < propsForCalculatorByFreeNew?.term
    : true;

  const conditions = [
    creditSumValid,
    isFlatPriceExists,
    isFlatPriceValid,
    isRateExists,
    !termError,
    !rateError,
    !flatPriceError,
    !firstPaymentPercentError,
    !firstPaymentCurrencyNewError,
    graceTermValid,
  ];
  return conditions.every(Boolean);
};
