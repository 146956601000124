import { Box, Typography, styled } from '@mui/material';
import { Colors, PrimaryColors } from '../../assets/mui-styles/Colors';

function MortgageColumnBox({ title, value, isBlack = false, type = 'rub', toFixedValue }) {
  const renderPrice = () => {
    return toFixedValue ? (
      <div>
        {new Intl.NumberFormat('ru-RU', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        })
          .format(Math.floor(Number(value) * 10) / 10)
          .replace(',', ', ')}
        ₽
      </div>
    ) : (
      <div>{new Intl.NumberFormat('ru-RU').format(Number.parseInt(value))} ₽</div>
    );
  };

  return (
    <EstimationItem sx={{ width: '100%' }} isblack={isBlack.toString()}>
      <EstimationItemHeading component='div' variant='h6' isblack={isBlack.toString()}>
        {title}
      </EstimationItemHeading>
      <EstimationItemSum isblack={isBlack.toString()}>
        {type === 'percent' ? <div>{Number(value).toFixed(1)}%</div> : renderPrice()}
      </EstimationItemSum>
    </EstimationItem>
  );
}

export default MortgageColumnBox;

const EstimationItem = styled(Box)(({ isblack }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  justifyContent: 'center',
  width: 'auto',
  padding: '20px',
  backgroundColor: isblack === 'true' ? PrimaryColors.primary : Colors.primary,
  border: Colors.superLightGrey,
  borderRadius: '0px',
}));

// Стили для заголовка элемента
const EstimationItemHeading = styled(Typography)(({ isblack }) => ({
  fontSize: '14px',
  lineHeight: '20px',
  margin: 0,
  color: isblack === 'true' ? Colors.primary : PrimaryColors.primary,
}));

// Стили для суммы
const EstimationItemSum = styled(Typography)(({ isblack }) => ({
  letterSpacing: '0px',
  marginBottom: '10px',
  fontSize: '28px',
  lineHeight: '32.68px',
  color: isblack === 'true' ? Colors.primary : PrimaryColors.primary,
}));
